import { App, ConfigProvider } from 'antd';
import "bootstrap/dist/js/bootstrap.min.js";
import type { GatsbyBrowser } from "gatsby";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "./src/context/AuthProvider";

import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import duration from "dayjs/plugin/duration";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(isBetween);
dayjs.extend(duration);
dayjs.extend(timezone);
dayjs.extend(utc);

const queryClient = new QueryClient();

export const wrapRootElement: GatsbyBrowser["wrapPageElement"] = ({ element }) => {
    return (
        <App>
            <ConfigProvider theme={{}}>
                <QueryClientProvider client={queryClient}>
                    <AuthProvider>
                        {element}
                    </AuthProvider>
                </QueryClientProvider>
            </ConfigProvider >
        </App>
    );
};