import { RegistrationValues } from "../types/auth.type";
import { api } from "./config/axios";
import type { CustomAxiosRequestConfig } from 'axios-auth-refresh/dist/utils';

const API_URL = `${process.env.GATSBY_BACKEND_URL}/`;
const customAxiosRequestConfig: CustomAxiosRequestConfig = { skipAuthRefresh: true };


class AuthService {
  login = async (username: string, password: string) => {
    const response = await api.post(
      API_URL + "auth/login/",
      { username, password },
      customAxiosRequestConfig
    )

    return response.data;
  }

  logout = async (refresh: string) => {
    const response = await api.post(
      API_URL + "auth/logout/",
      { "refresh_token": refresh },
      customAxiosRequestConfig
    )

    return response.data;
  }

  refresh = async (refresh: string) => {
    const response = await api.post(
      API_URL + "auth/token/refresh/",
      { "refresh": refresh },
      customAxiosRequestConfig
    )

    return response.data;
  }

  register = async (details: RegistrationValues) => {
    const response = await api.post(
      API_URL + "auth/register/",
      {
        "username": details.username,
        "first_name": details.firstname,
        "last_name": details.lastname,
        "email": details.email,
        "password": details.password
      },
      customAxiosRequestConfig
    );

    return response.data;
  }
}

export const AuthAPI = new AuthService();
