import { App } from "antd";
import { navigate } from "gatsby";
import jwtDecode from "jwt-decode";
import React, { createContext, useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { AuthAPI } from "../../services/auth.service";
import { api } from "../../services/config/axios";
import { AuthProps, AuthenticationContextType, RegistrationValues, User } from "../../types/auth.type";


const AuthContext = createContext<AuthenticationContextType | null>(null);

export default AuthContext;

export const useAuth = () => {
  return useContext(AuthContext) as AuthenticationContextType;
}

interface LoginParams {
  username: string,
  password: string
}

export const AuthProvider: React.FC<AuthProps> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [access, setAccess] = useLocalStorage("access", null);
  const [refresh, setRefresh] = useLocalStorage("refresh", null);
  const { message } = App.useApp();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    api.defaults.headers.common['Authorization'] = access ? `Bearer ${access}` : null;
    queryClient.invalidateQueries();
    if (access && !user) {
      setUser(jwtDecode(access));
    }
  }, [access])

  const refreshMutation = useMutation({
    mutationFn: async (refresh: string) => await AuthAPI.refresh(refresh),
    onSuccess(data, variables, context) {
      console.info('data', data);
      setAccess(data.access);
      setRefresh(data.refresh);
    },
    onError(error, variables, context) {
      message.error(`Could not refresh credentials for user ${user?.username}.`)
      setUser(undefined);
      setAccess(null);
      setRefresh(null);
    },
  })

  const refreshUser = async () => {
    await refreshMutation.mutateAsync(refresh);
    navigate("/");
  }

  const loginMutation = useMutation({
    mutationFn: async (params: LoginParams) => AuthAPI.login(params.username, params.password),
    onSuccess(data, variables, context) {
      setAccess(data.access);
      setRefresh(data.refresh);
      navigate("/overview");
    },
    onError(error, variables, context) {
      message.error(`Could not login. Please verify your username and password are correct.`)
      setUser(undefined);
      setAccess(null);
      setRefresh(null);
    },
  })

  const loginUser = async (username: string, password: string) => {
    await loginMutation.mutateAsync({ username, password });
    navigate("/");
  };

  const logoutMutation = useMutation({
    mutationFn: async (refresh: string) => await AuthAPI.logout(refresh),
    onMutate() {
      setUser(undefined);
      setAccess(null);
      setRefresh(null);
    },
    onError(error, variables, context) {
      message.error(`Failed to logout ${user?.username}! ${error}`);
    },
    onSettled(data, error, variables, context) {
      message.success(`${user?.username} logged out.`);
    },
  })

  const logoutUser = async () => {
    await logoutMutation.mutateAsync(refresh);
    navigate("/", { replace: true });
  };

  const registerMutation = useMutation({
    mutationFn: async (details: RegistrationValues) => await AuthAPI.register(details),
    onSettled(data, error, variables, context) {
      console.info(data);
      console.error(error);
    },
  })

  const registerUser = async (details: RegistrationValues) => {
    await registerMutation.mutateAsync(details);
    navigate("/login");
  };

  React.useEffect(() => {
    setLoading([registerMutation.isLoading, refreshMutation.isLoading, loginMutation.isLoading, logoutMutation.isLoading].some(e => e));
  }, [registerMutation, refreshMutation, loginMutation, logoutMutation])

  return (
    <AuthContext.Provider value={{
      user,
      access,
      refresh,
      setUser,
      registerUser,
      loginUser,
      logoutUser,
      refreshUser
    }}>
      {children}
    </AuthContext.Provider>
  );
};