exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-budgets-[id]-tsx": () => import("./../../../src/pages/budgets/[id].tsx" /* webpackChunkName: "component---src-pages-budgets-[id]-tsx" */),
  "component---src-pages-budgets-index-tsx": () => import("./../../../src/pages/budgets/index.tsx" /* webpackChunkName: "component---src-pages-budgets-index-tsx" */),
  "component---src-pages-categories-[id]-tsx": () => import("./../../../src/pages/categories/[id].tsx" /* webpackChunkName: "component---src-pages-categories-[id]-tsx" */),
  "component---src-pages-categories-index-tsx": () => import("./../../../src/pages/categories/index.tsx" /* webpackChunkName: "component---src-pages-categories-index-tsx" */),
  "component---src-pages-import-index-tsx": () => import("./../../../src/pages/import/index.tsx" /* webpackChunkName: "component---src-pages-import-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-overview-index-tsx": () => import("./../../../src/pages/overview/index.tsx" /* webpackChunkName: "component---src-pages-overview-index-tsx" */),
  "component---src-pages-register-index-tsx": () => import("./../../../src/pages/register/index.tsx" /* webpackChunkName: "component---src-pages-register-index-tsx" */),
  "component---src-pages-summary-index-tsx": () => import("./../../../src/pages/summary/index.tsx" /* webpackChunkName: "component---src-pages-summary-index-tsx" */),
  "component---src-pages-transaction-types-index-tsx": () => import("./../../../src/pages/transaction_types/index.tsx" /* webpackChunkName: "component---src-pages-transaction-types-index-tsx" */),
  "component---src-pages-transactions-index-tsx": () => import("./../../../src/pages/transactions/index.tsx" /* webpackChunkName: "component---src-pages-transactions-index-tsx" */)
}

