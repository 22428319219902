import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

const BASE_URL = `${process.env.GATSBY_BACKEND_URL}`;

export const api = axios.create({
    withCredentials: false,
    baseURL: `${BASE_URL}/api/v1`,
})

const getAccessToken = () => {
    try {
        return JSON.parse(localStorage.getItem('access') || "")
    } catch {
        return ""
    }
}

const getRefreshToken = () => {
    try {
        return JSON.parse(localStorage.getItem('refresh') || "")
    } catch {
        return ""
    }}

// Function that will be called to refresh authorization
const refreshAuth = async (request: any) => {
    return api.post(
        `${BASE_URL}/auth/token/refresh/`,
        { "refresh": getRefreshToken() },
    ).then((response) => {
        localStorage.setItem('access', JSON.stringify(response.data.access));
        localStorage.setItem('refresh', JSON.stringify(response.data.refresh));
        request.response.config.headers['Authorization'] = `Bearer ${getAccessToken()}`;
        return Promise.resolve();
    }).catch((e) => {
        localStorage.setItem('refresh', JSON.stringify(null));
        window.location.href = "/login/";
        return Promise.reject();
    });
}

createAuthRefreshInterceptor(
    api,
    refreshAuth,
    { statusCodes: [403] }
)

api.interceptors.request.use((request) => {
    if (getAccessToken()) {
        request.headers['Authorization'] = `Bearer ${getAccessToken()}`;
    }
    return request;
})

